<template>
    <div>
        <!-- para edicion de la info -->
        <select-page
            :registrosMax='maxPers'
            :total="totalPers"
            :query='queryPers'
            :page='persPage'
            @carga='cargaPers'
        >
            <template v-slot="{dat}">
                <div class="d-flex align-center">
                    <v-autocomplete
                        v-model="dt"
                        :items="itemsPers"
                        :loading="loadingPers"
                        :label="label"
                        :search-input.sync="queryPers"
                        item-text="username"
                        item-value="username"
                        no-filter
                        dense
                        rounded
                        filled
                        :rules="rules"
                    >
                        <template v-slot:item="dat">
                            <div class="d-flex flex-column pb-2">
                                <div class="font-weight-bold text-body-2">{{dat.item.nombreCompleto}}</div>
                                <div class="text-caption">{{dat.item.curp}} </div>
                                <div class="text-caption">{{dat.item.especialidad}} ({{dat.item.cedula}})</div>
                            </div>
                        </template>

                        <template v-slot:append-item>
                            <v-pagination
                                v-model="persPage"
                                circle
                                :length="dat.pageLength"
                                total-visible="7"
                            ></v-pagination>
                        </template>
                    </v-autocomplete>
                </div>
            </template>
        </select-page>
    </div>
</template>

<script>
import SelectPage from '@/components/com/SelectPage'
export default {
    components:{
        'select-page':SelectPage
    },
    props:{
        model:String,
        datoGrande:String,
        label:String,
        edita:Boolean,
        editando:Boolean,
        doble:Boolean,
        rules:Array,
    },
    data:()=>({
        editandoDt:false,
        datoViejo:'',
        itemsPers:[],
        totalPers:0,
        loadingPers:false,
        queryPers:'',
        maxPers:10,
        persPage:1,
    }),
    methods:{
        async cargaPers(page, query, start){
            this.itemsPers=[]
            if (!this.loadingPers && this.queryPers.length>=3){
                this.loadingPers = true
                try{
                    let per = await this.$http({
                        url: "/principal/listaTrabajadores",
                        params:{
                            page:page,
                            query:this.queryPers,
                            start:start,
                            limit:this.maxPers,
                        },
                    })
                    this.itemsPers=per.data.root
                    this.totalPers = per.data.total
                    this.loadingPers = false
                    this.perPage = 1
                }catch(err){
                    this.loadingPers = false
                    this.$store.dispatch('security/revisaError',err)
                }
            }
        },
    },
    computed:{
        dt:{
            get(){
                return (this.model)
            },
            set(val){
                this.$emit('update:model',val)
            }
        },
    },
}
</script>

<style>
</style>